<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import { fly } from "svelte/transition";
  import { orgOperators, project } from "$lib/services/store";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import ToplistRow from "$lib/admin/components/blocks/helpers/ToplistRow.svelte";
  import { getOrdinal } from "$lib/common/helpers/ordinalFormat";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  export let operatorId: number;
  export let tournamentName: string;
  export let operator: Operator | null = null;
  export let stats = {
    score: 0,
    rank: 0,
    spinsLeft: 0,
    spinsTotal: 0,
  };

  export let props = {
    operatorId,
    toplistFocus: "tournament-sponsor",
    toplistPaymentMethodProvider: "",
  };

  const lang = $project.settings.lang ?? "en";
  const operators: Operator[] = $orgOperators;
  let previousScore = stats.score;
  let scoreIncreased = false;

  $: if (operators) {
    operator = operators.find((x) => x.id === Number(operatorId)) ?? null;
  }

  $: if (stats) {
    if (stats.score !== previousScore) {
      scoreIncreased = stats.score > previousScore;
      previousScore = stats.score;
    }
  }

  const hasValidStats = (stats: any) => {
    return stats.score > 0 || stats.spinsTotal - stats.spinsLeft > 0;
  };
</script>

<div class="statistics-container">
  <div class="statistics">
    <div class="tournament-info">
      <h1 class="tournament-name">{tournamentName}</h1>
      {#if operator}
        <span class="offered-by">
          <Translate text="Offered by" />
          {operator?.name}</span>
        <div class={`toplist tournament-sponsor`} class:show-full={true}>
          <ToplistRow {props} {operator} toplistPosition={1} dataElement="" />
        </div>
      {/if}
    </div>

    <div class="user-statistics">
      <h2 class="tournament-stats-label stats-title">
        <Translate text="Statistics" />
      </h2>

      <div class="box score">
        <div class="title"><Translate text="Score" /></div>
        <div
          class="value"
          in:fly={{ y: scoreIncreased ? 20 : -20, duration: 300 }}
          class:increased={scoreIncreased}
          class:decreased={!scoreIncreased}>
          {hasValidStats(stats) ? stats.score : "-"}
        </div>
      </div>

      <div class="box rank">
        <div class="title"><Translate text="Rank" /></div>
        <div class="value">
          {#if hasValidStats(stats) && stats.rank}
            {getOrdinal(stats.rank, lang)}
          {:else}
            -
          {/if}
        </div>
      </div>

      <div class="box spins">
        <div class="title"><Translate text="Spins" /></div>
        <div class="value" class:low-spins={stats.spinsLeft <= 5}>
          {hasValidStats(stats)
            ? `${stats.spinsLeft} / ${stats.spinsTotal}`
            : `${stats.spinsTotal} / ${stats.spinsTotal}`}
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .container {
    background-color: #282828;
    color: #fff;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .back-button {
    font-weight: 300;
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    width: fit-content;
  }
  .statistics {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    grid-template-areas:
      "tournament-info"
      "user-statistics";
  }

  @media only screen and (min-width: 991px) {
    .statistics {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 20px;
      grid-template-areas: "tournament-info user-statistics";
    }
  }
  .tournament-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 36px;
    gap: 0px 0px;
    grid-template-areas:
      "."
      "offered-by";
    grid-area: tournament-info;
  }

  .user-statistics {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 40px 60px;
    gap: 0px 10px;
    grid-template-areas:
      "stats-title stats-title stats-title"
      "score rank spins";
    grid-area: user-statistics;
  }

  @media only screen and (min-width: 991px) {
    .user-statistics {
      grid-template-rows: 60px 80px;
    }
  }

  .stats-title {
    grid-area: stats-title;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
  .rank {
    justify-self: center;
    grid-area: rank;
    width: 100px;
  }
  .score {
    position: relative;
    overflow: hidden;
    justify-self: center;
    grid-area: score;
    width: 100px;
  }
  .spins {
    justify-self: center;
    grid-area: spins;
    width: 100px;
  }
  .tournament-name {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
  }
  .offered-by {
    font-size: 16px;
    font-weight: 300;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #3f3f3f;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #575757;
    padding: 8px;
    font-size: 16px;
    transition:
      transform 0.2s ease,
      box-shadow 0.2s ease;
  }

  .box:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .box .title {
    font-size: 16px;
    font-weight: 400;
  }

  .box .value {
    color: #ffcf3a;
  }

  .value {
    transition: color 0.3s ease;
  }

  .increased {
    color: #4caf50;
  }

  .decreased {
    color: #f44336;
  }

  .low-spins {
    color: #f44336;
    animation: pulse 2s infinite;
  }
  .statistics-container {
    position: relative;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
</style>
