<script lang="ts">
  import { project } from "$lib/services/store";
  import Skeleton from "$lib/main/components/Skeleton.svelte";
  import { formatUnixDate } from "$lib/common/helpers/date";
  import type { Tab } from "@/src/types/components/DataTable";
  import { createEventDispatcher } from "svelte";
  import { sanitizeTournamentName, generateTournamentSlug } from "$lib/services/tournaments/nameUtils";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import EmptyLeaderboard from "./EmptyLeaderboard.svelte";

  export let tabs: Tab[];
  export let hideHeader: boolean;
  export let data: any[];
  export let isLoading: boolean;
  export let activeTab: number;
  export let hasContainer: boolean;

  const dispatch = createEventDispatcher();

  const baseRoute = ($project.settings.tournamentsUrl ?? "turneringar").replace(/^\/+/, "");

  function generateTournamentUrl(tournamentId: number, name: string): string {
    const slug = generateTournamentSlug(tournamentId, name);
    return `/${baseRoute}/${slug}`;
  }

  let skeletons = Array(5).fill({});

  function isEmptyLeaderboard(data: any[]): boolean {
    return data.length === 1 && "isEmptyLeaderboard" in data[0] && data[0].isEmptyLeaderboard === true;
  }

  function filterData(row: any, currentTab: Tab) {
    const columns = Array.isArray(currentTab.selectedColumns) ? currentTab.selectedColumns : [];

    for (const col of columns) {
      if (
        col.key in row &&
        typeof row[col.key] === "boolean" &&
        col.booleanValue !== "any" &&
        col.booleanValue !== undefined
      ) {
        if (row[col.key] !== col.booleanValue) {
          return false;
        }
      }
    }
    return true;
  }

  function getEndpointClass(endpoint?: string): string {
    return endpoint ? endpoint.toLowerCase() : "";
  }

  function setActiveTab(index: number) {
    dispatch("activeTabChange", index);
  }

  function navigateToTournament(row: any) {
    if (row.tournamentId && row.name && (row.isLive || row.scheduleTimerTitle === "finished")) {
      const url = generateTournamentUrl(row.tournamentId, row.name);
      window.location.href = url;
    }
  }
</script>

<div class="tabs-wrapper" class:container={hasContainer}>
  <h3><Translate text="More Tournaments" /></h3>
  <div class="tabs-container">
    {#each tabs as tab, index}
      <div role="presentation" class={index === activeTab ? "tab active" : "tab"} on:click={() => setActiveTab(index)}>
        <Translate text={tab.title} />
      </div>
    {/each}
  </div>

  {#if !isLoading}
    {#if tabs[activeTab].endpoint === "leaderboard" && isEmptyLeaderboard(data)}
      <EmptyLeaderboard />
    {:else if data.length > 0}
      <div class="table-content-parent">
        <div class="table-content-div">
          <table class="table-content {getEndpointClass(tabs[activeTab].endpoint)}">
            {#if !hideHeader}
              <thead>
                <tr class="row header">
                  {#each tabs[activeTab].selectedColumns.filter((col) => !col.hidden) as column}
                    <th>{column.displayName}</th>
                  {/each}
                  <th>Action</th>
                </tr>
              </thead>
            {/if}
            <tbody>
              {#each data.filter((row) => filterData(row, tabs[activeTab])) as row}
                <tr
                  class="row {(row.isLive || row.scheduleTimerTitle === 'finished') && 'clickable'}"
                  on:click={() => navigateToTournament(row)}>
                  {#each tabs[activeTab].selectedColumns.filter((col) => !col.hidden) as column}
                    <td>
                      {#if column.key === "logoImage" && "backgroundImage" in row}
                        <div class="logo-container">
                          <img
                            class="background-image"
                            src={row.backgroundImage}
                            referrerpolicy="no-referrer"
                            alt="Background" />
                          <img src={row.logoImage} referrerPolicy="no-referrer" alt="Logo" class="logo-image" />
                        </div>
                      {:else if column.key in row}
                        {#if typeof row[column.key] === "boolean"}
                          {row[column.key] ? "True" : "False"}
                        {:else if column.key === "startDate" || column.key === "endDate"}
                          {formatUnixDate(row[column.key])}
                        {:else if column.key === "name"}
                          {sanitizeTournamentName(row[column.key])}
                        {:else}
                          {row[column.key] !== undefined ? row[column.key] : "N/A"}
                        {/if}
                      {:else}
                        N/A
                      {/if}
                    </td>
                  {/each}
                  <td>
                    {#if row.tournamentId && row.name}
                      {#if row.isLive}
                        <a class="play-cta" href={generateTournamentUrl(row.tournamentId, row.name)}>
                          <Translate text="Play Tournament" />
                        </a>
                      {:else if row.scheduleTimerTitle === "finished"}
                        <a class="play-cta" href={generateTournamentUrl(row.tournamentId, row.name)}>
                          <Translate text="Check Leaderboard" />
                        </a>
                      {:else}
                        <button class="play-cta disabled" disabled>
                          <Translate text="Tournament Not Live Yet" />
                        </button>
                      {/if}
                    {/if}
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    {/if}
  {:else}
    <tbody>
      {#each skeletons as _, index}
        <tr>
          <td colspan={tabs[activeTab].selectedColumns.filter((col) => !col.hidden).length + 1}>
            <Skeleton
              maxWidth="100%"
              height="20px"
              baseColor="rgb(23, 23, 23)"
              highlightColor="rgb(38, 38, 38)"
              customStyles="margin-bottom: 10px;" />
          </td>
        </tr>
      {/each}
    </tbody>
  {/if}
</div>

<style>
  .tabs-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0 auto;
    padding: 24px 0;
    max-width: 100vw;
    @media (min-width: 992px) {
      max-width: 1140px;
    }
  }
  .tabs-container {
    display: flex;
    min-width: 0;
    background-color: #282828;
    color: white;
  }
  .tab {
    width: 180px;
    padding: 8px 0;
    text-align: center;
    border-bottom: 0;
    cursor: pointer;
  }
  .tab.active {
    border-bottom: 3px solid #cc3129;
  }
  .table-content-parent {
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      background: #e0e0e0;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #cc3129;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #9f2620;
    }
  }
  .table-content-div {
    min-width: 900px;
    padding-bottom: 20px;

    @media (min-width: 992px) {
      padding-bottom: 0;
    }
  }
  .table-content {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
  }

  .table-content.live,
  .table-content.schedule,
  .table-content.results {
    border-spacing: 0 6px;
  }

  .table-content.live .row td:first-child,
  .table-content.schedule .row td:first-child,
  .table-content.results .row td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid #717171;
    padding: 0;
    overflow: hidden;
  }

  .table-content.live .row td,
  .table-content.schedule .row td,
  .table-content.results .row td {
    border-top: 1px solid #717171;
    border-bottom: 1px solid #717171;
  }

  .table-content.live .row td:last-child,
  .table-content.schedule .row td:last-child,
  .table-content.results .row td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid #717171;
    text-align: right;
    padding-right: 8px;
  }

  .table-content .row th,
  .table-content .row td {
    padding: 4px;
    text-align: left;
    color: #fff;
  }

  .table-content .row {
    background-color: #3f3f3f;
  }

  .table-content .row th {
    background-color: transparent;
  }

  .row.clickable {
    cursor: pointer;
    &:hover {
      background-color: #2f2f2f;
    }
  }

  .play-cta {
    padding: 12px 27px;
    background-color: #cc3129;
    color: white;
    border-radius: 6px;
    white-space: nowrap;
    transition: background-color 0.3s;
    &:hover {
      background-color: #a82720;
    }
  }

  .play-cta.disabled,
  .play-cta.disabled:hover {
    background-color: gray;
    cursor: not-allowed;
    color: #ccc;
  }

  .play-cta.disabled {
    pointer-events: none;
  }

  .no-data {
    color: white;
    text-align: center;
    padding: 20px;
  }

  .logo-container {
    position: relative;
    width: 170px;
    height: 62px;
    overflow: hidden;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 95%;
    object-fit: contain;
    z-index: 2;
  }
</style>
